function sliderImg() {
  let slider = document.querySelector(".img-slider__slider");
  let btnPrev = document.querySelectorAll(".img-slider__slider .btn-nav-prev");
  let btnNext = document.querySelectorAll(".img-slider__slider .btn-nav-next");
  let sliderItems = document.querySelectorAll(".img-slider__content");
  let countCurrent = document.querySelectorAll(".img-slider__count .count__current");
  let countTotal = document.querySelectorAll(".img-slider__count .count__total");

  let itemsLenght = sliderItems.length;
  let count = 100 / itemsLenght;
  let itemIndex = 0;

  slider.style.width = `${100 * itemsLenght}%`;
  // init total
  countTotal.forEach((total) => {
    total.textContent = `0${itemsLenght}`;
  });

  // Dynamic hide buttons
  let allPrev = Array.from(btnPrev);
  let allNext = Array.from(btnNext);

  if (allPrev.length > 0) {
    allPrev[0].style.display = "none";
  }

  let lastNextBtn = allNext[allNext.length - 1];
  if (lastNextBtn) {
    lastNextBtn.style.display = "none";
  }

  //
  countCurrent.forEach((current) => {
    current.textContent = `0${itemIndex + 1}`;
  });

  btnNext.forEach((next, index) => {
    next.addEventListener("click", () => {
      console.log(index);
      itemIndex = (itemIndex + 1) % itemsLenght;
      slider.style.transform = `translate(${itemIndex * -count}%)`;

      countCurrent.forEach((current, index) => {
        current.textContent = `0${index + 1}`;
      });
    });
  });

  btnPrev.forEach((prev, index) => {
    prev.addEventListener("click", () => {
      console.log(index);
      itemIndex = (itemIndex - 1 + itemsLenght) % itemsLenght;
      slider.style.transform = `translate(${itemIndex * -count}%)`;
      countCurrent.textContent = `0${index + 1}`;
    });
  });
}

let slider = document.querySelector(".img-slider__slider");
if (slider) {
  sliderImg();
}
