function sliderInfo() {
  let btnPrev = document.querySelector(".container-slider-info .btn-prev");
  let btnNext = document.querySelector(".container-slider-info .btn-next");
  let sliderItems = document.querySelectorAll(
    ".container-slider-info .content__item"
  );
  let points = document.querySelectorAll(".container-slider-info .nav__item");
  let progressBarLine = document.querySelector(
    ".container-slider-info .progress-bar-container"
  );
  let navHeadings = document.querySelector(
    ".container-slider-info .slider__nav__headings"
  );

  let itemsLenght = sliderItems.length;
  let count = 100 / itemsLenght;
  let itemIndex = 0;

  slider.style.width = `${100 * itemsLenght}%`;

  // Init active point heading navigation
  points[0].classList.add("active");

  function activePoint() {
    points.forEach((point, index) => {
      point.classList.remove("active");
      if (itemIndex == index) {
        point.classList.add("active");
      }
    });
  }

  function progressBar() {
    let lenght = itemsLenght;
    let width = (100 / lenght) * (itemIndex + 1);
    progressBarLine.style.width = `${width}%`;
  }

  // Navigation button next
  btnNext.addEventListener("click", () => {
    itemIndex = (itemIndex + 1) % itemsLenght;
    slider.style.transform = `translate(${itemIndex * -count}%)`;

    let heading = navHeadings.querySelector(".nav__item");
    let headingWidth = heading.offsetWidth;
    countHeadings = itemIndex * -headingWidth;
    navHeadings.style.transform = `translate(${countHeadings}px)`;

    // Call active point heading navigation
    activePoint();
    progressBar();
  });

  // Navigation button prev
  btnPrev.addEventListener("click", () => {
    itemIndex = (itemIndex - 1 + itemsLenght) % itemsLenght;
    slider.style.transform = `translate(${itemIndex * -count}%)`;

    let heading = navHeadings.querySelector(".nav__item");
    let headingWidth = heading.offsetWidth;
    countHeadings = itemIndex * headingWidth;
    navHeadings.style.transform = `translate(${-countHeadings}px)`;

    // Call active point heading navigation
    activePoint();
    progressBar();
  });

  // Navigation points
  points.forEach((point, index) => {
    point.addEventListener("click", () => {
      itemIndex = index;
      progressBar();

      points.forEach((point) => {
        point.classList.remove("active");
      });

      if (itemIndex === index) {
        point.classList.add("active");
        slider.style.transform = `translate(${itemIndex * -count}%)`;
      }
    });
  });

  // Init call progress bar function
  window.onload = progressBar();
}

let slider = document.querySelector(".container-slider-info .slider-items");
if (slider) {
  sliderInfo();
}
