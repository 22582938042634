function heroSlider() {
  let slider = document.querySelector(".hero-slider");
  let btnPrev = document.querySelectorAll(".slider-item-content .btn-prev");
  let btnNext = document.querySelectorAll(".slider-item-content .btn-next");
  let sliderItems = document.querySelectorAll(".slider-item");
  let nav = document.querySelector(".navigation");
  let progressBarLine = document.querySelectorAll(
    ".slider-item-content .progress-bar"
  );
  let navigationContainer = document.querySelectorAll(".navigation-container");

  let itemsLenght = sliderItems.length;
  let count = 100 / itemsLenght;
  let itemIndex = 0;

  slider.style.width = `${100 * itemsLenght}%`;

  navigationContainer.forEach((container) => {
    container.appendChild(nav.cloneNode(true));
  });

  function pointsItems() {
    let points = document.querySelectorAll(
      ".slider-item.active .navigation-container button"
    );
    points.forEach((point, index) => {
      point.addEventListener("click", () => {
        itemIndex = index;

        progressBar();

        activeItem();
        sliderItems[itemIndex].classList.add("active");

        point.classList.remove("active");

        if (itemIndex === index) {
          point.classList.add("active");
          slider.style.transform = `translate(${itemIndex * -count}%)`;
        }

        pointsItems();
      });
    });
  }

  pointsItems();

  function progressBar() {
    let lenght = itemsLenght;
    let width = (100 / lenght) * (itemIndex + 1);

    progressBarLine.forEach((bar) => {
      bar.style.width = `${width}%`;
    });
  }

  function activeItem() {
    sliderItems.forEach((item) => {
      item.classList.remove("active");
    });
  }

  btnNext.forEach((next) => {
    next.addEventListener("click", () => {
      itemIndex = (itemIndex + 1) % itemsLenght;
      slider.style.transform = `translate(${itemIndex * -count}%)`;
      activeItem();
      sliderItems[itemIndex].classList.add("active");
      progressBar();

      pointsItems();
    });
  });

  btnPrev.forEach((prev) => {
    prev.addEventListener("click", () => {
      itemIndex = (itemIndex - 1 + itemsLenght) % itemsLenght;
      slider.style.transform = `translate(${itemIndex * -count}%)`;
      // activeItem();
      sliderItems[itemIndex].classList.add("active");

      // Call active point heading navigation
      // activePoint();
      // navPoints();
      progressBar();
      pointsItems();
    });
  });

  // Init call progress bar function
  window.onload = progressBar();
}

let isSlider = document.querySelector(".hero-slider");
if (isSlider) {
  heroSlider();
}
