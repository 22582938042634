function accordion() {
  let accordionItems = document.querySelectorAll(".accordion__item");

  accordionItems.forEach((item) => {
    item.addEventListener("click", function () {
      item.classList.toggle("accordion__item-active");
    });
  });
}

let accordionElement = document.querySelector(".accordion-section");

if (accordionElement) {
  accordion();
}
