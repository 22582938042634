// Header - replace cart count amnount
let headerCartCount = document.querySelector(".cart.button .cart-count");

let menuCartCount = document.querySelector(
  ".cart-popup-container .cart.button .cart-count"
);

let menuCartCountMobile = document.querySelector(
  ".menu-properties__header .cart-count"
);

let headerCartNumber = headerCartCount.innerText.replace(/\D/g, "");
let menuCartNumber = menuCartCount.innerText.replace(/\D/g, "");

headerCartCount.innerText = headerCartNumber;
menuCartCount.innerText = menuCartNumber;
menuCartCountMobile.innerText = menuCartNumber;

// Mobile menu

let html = document.querySelector("html");
let header = document.querySelector("header");
let menuIcon = document.querySelector(".mobile-icon");
let mobileMenu = document.querySelector(".mobile-menu");
let cartPopup = document.querySelector(".cart-popup-container");
let cartIcon = document.querySelector("header .cart.icon");

menuIcon.addEventListener("click", function () {
  this.classList.toggle("active");
  mobileMenu.classList.toggle("active");
  header.classList.toggle("active-mobile-menu");
  html.classList.toggle("mobile-menu--open");
});

// Cart menu sidebar

let popupContainer = document.querySelector(".cart-popup-container");
let closeBtn = document.querySelector(".cart-popup-container .icon-cross");

if (closeBtn) {
  closeBtn.addEventListener("click", function () {
    popupContainer.classList.remove("active");
  });
}

cartIcon.addEventListener("click", function () {
  cartPopup.classList.toggle("active");
});
