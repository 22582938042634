function iconAnimation() {
  let backgroundCircle = document.querySelector(".background-circle");

  icons.forEach((icon, index) => {
    icon.addEventListener("mouseover", () => {
      let deg = (360 / icons.length) * (index + 1);
      backgroundCircle.style.background = `conic-gradient(#A6E4ED ${deg}deg, #fff 0deg)`;
    });
  });
}

let icons = document.querySelectorAll(".icon-js");

if (icons) {
  iconAnimation();
}
