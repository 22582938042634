// const container = document.querySelector(".swipe-container");
// console.log(container)

// document.querySelector(".slider-swipe").addEventListener("input", (e) => {
//   container.style.setProperty("--position", `${e.target.value}%`);
// });
function swiper() {


  container.querySelector(".slider-swipe").addEventListener("input", (e) => {
    container.style.setProperty("--position", `${e.target.value}%`);
  });
}

let container = document.querySelector(".swipe-container");
if (container) {
  swiper();
}






