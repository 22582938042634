function reviewSlider() {
  let sliderRev = document.querySelector(".review-slider__slider");
  let btnPrev = document.querySelectorAll(".review-slider__slider .btn-nav-prev");
  let btnNext = document.querySelectorAll(".review-slider__slider .btn-nav-next");
  let sliderItems = document.querySelectorAll(".review-slider__slider .review-slider__item");
  let reviewPerson = document.querySelectorAll('.review-wrapper')

  let itemsLenght = sliderItems.length;
  let count = 100 / itemsLenght;
  let itemIndex = 0;

  sliderRev.style.width = `${100 * itemsLenght}%`;

  // Init active persin nav bar
  reviewPerson[0].classList.add("active");
  console.log(reviewPerson)

function activePerson() {
    reviewPerson.forEach((person, index) => {
      person.classList.remove("active")
      console.log("Active")
      if (itemIndex == index) {
        person.classList.add("active")
      }
    })
  }


  btnPrev.forEach((next, index) => {
    next.addEventListener("click", () => {
      itemIndex = (itemIndex - 1 + itemsLenght) % itemsLenght;
      sliderRev.style.transform = `translate(${itemIndex * -count}%)`;

      activePerson();
    });
  });

  btnNext.forEach((next, index) => {
    next.addEventListener("click", () => {
      console.log(itemIndex - 1);
      itemIndex = (itemIndex + 1) % itemsLenght;
      sliderRev.style.transform = `translate(${itemIndex * -count}%)`;

      activePerson();
    });
  });

  reviewPerson.forEach((person, index) => {
    person.addEventListener("click", () => {
      itemIndex = index;
      activePerson();

      if (itemIndex === index) {
        person.classList.add("active")
        console.log(index)
        sliderRev.style.transform = `translate(${itemIndex * -count}%)`;
      }
    })
  })

}

let sliderRev = document.querySelector(".review-slider__slider");
if (sliderRev) {
  reviewSlider();
}
