function toggle () {

  const toggleItems = document.querySelectorAll(".toggle-item");
  const nextBtn = document.querySelector("#nextButton");
  const prevBtn = document.querySelector("#prevButton");
  let itemIndex = 0;

  toggleItems[0].classList.add("active");
  let length = toggleItems.length;

  function setActiveItem(index) {
    toggleItems.forEach((item, i) => {
      item.classList.toggle("active", i === index);
    });
  }

  function goToNextItem() {
    itemIndex = (itemIndex + 1) % length;
    console.log(itemIndex)
    setActiveItem(itemIndex);
  }

  function goToPrevItem() {
    itemIndex = (itemIndex - 1 + length) % length;
    console.log(itemIndex)
    setActiveItem(itemIndex);
  }

  nextBtn.addEventListener("click", goToNextItem);
  prevBtn.addEventListener("click", goToPrevItem);

  toggleItems.forEach((item, index) => {
    item.addEventListener("click", () => {
      itemIndex = index;
      setActiveItem(itemIndex);
    });
  });

};

const toggleWrapper = document.querySelector(".toggle-section-wrapper");
if (toggleWrapper) {
  toggle();
}
