// In this file you can import assets like images or stylesheets

// Fonts
import AtypDisplayRegular from "./fonts/AtypDisplay-Regular.ttf";

// SCSS utils
import "./client/css/utils/variables.scss";
import "./client/css/utils/graphic.scss";

// SCSS patterns
import "./client/css/patterns/typography.scss";
import "./client/css/patterns/layouts.scss";
import "./client/css/patterns/header.scss";
import "./client/css/patterns/footer.scss";
import "./client/css/components/sections.scss";

// SCSS components
import "./client/css/components/buttons.scss";
import "./client/css/components/slider.scss";
import "./client/css/components/secondary-intro.scss";
import "./client/css/components/components-sections.scss";
import "./client/css/components/slider-info.scss";
import "./client/css/components/technology.scss";
import "./client/css/components/technology-clean.scss";
import "./client/css/components/slider-img.scss";
import "./client/css/components/accordion.scss";
import "./client/css/components/contact.scss";
import "./client/css/components/product-detail.scss";
import "./client/css/components/heading-slider.scss";
import "./client/css/components/reviews-slider.scss";
import "./client/css/components/additional-info.scss";
import "./client/css/components/payment-process__container.scss";
import "./client/css/components/cart.scss";
import "./client/css/components/summary.scss";
import "./client/css/components/steps.scss";
import "./client/css/components/cart-page.scss";
import "./client/css/components/partner-logos.scss";
import "./client/css/components/products-loop.scss";
import "./client/css/components/landing-page.scss";

// Javascript
import "./client/js/hero-slider.js";
import "./client/js/swipe.js";
import "./client/js/header.js";
import "./client/js/slider-info.js";
import "./client/js/toggle.js";
import "./client/js/circle.js";
import "./client/js/slider-img.js";
import "./client/js/accordion.js";
import "./client/js/reviews-slider.js";
import "./client/js/anchor.js";

// Images
import logoBlack from "./images/Rafael-logo-symbols-black.svg";
import logoWhite from "./images/Rafael-logo-symbols-white.svg";
import sliderHp from "./images/homepage-slider-img-1.png";
import glassesMan from "./images/glasses-man.png";
import productThumbnail from "./images/product-thumbnail.webp";
import productTouchDisplay from "./images/display-product.webp";
import glassesSwipe from "./images/glasses-swipe.png";
import swipeBefore from "./images/glasses-swipe-modified.png";
import dust from "./images/dust.png";
import dustDetail from "./images/dust-detail.png";
import abstractTechnology from "./images/abstract-technology.png";
import cleanGlasses from "./images/glasses-clean.png";
import drawing from "./images/man-drawing.png";
import abstractGlass from "./images/circle-glass-black-background.png";
import arkady from "./images/arkady-pankrac.png";
import palladium from "./images/palladium.png";
import chodov from "./images/westfield_chodov.png";
import people from "./images/people.png";
import reviewPersonOne from "./images/review-ida.png";
import reviewPersonTwo from "./images/review-pavlina.png";
import reviewPersonThree from "./images/review-svjetlana.png";
import manGlasses from "./images/man-glasses.jpg";
import mallLogo from "./images/mall-logo.png";
import fnMotolLogo from "./images/fn-motol.png";
import dopravniLogo from "./images/dopravni-podnik-logo.png";
import paintingPhoto from "./images/painting-photo-product.webp";
import ipadDisplay from "./images/ipad-product-display.webp";
import cameraMan from "./images/camera-man.webp";
import cameraProduct from "./images/camera-product.webp";
import dentist from "./images/dentist.webp";
import snowboardMan from "./images/snowboard-man.webp";
import glassesBefore from "./images/glasses-swipe-before.webp";
import glassesAfter from "./images/glasses-swipe-after.webp";
import heroSlider1 from "./images/hero-slider-img_1.jpg";
import heroSlider2 from "./images/hero-slider-img_2.jpg";
import heroSlider3 from "./images/hero-slider-img_3.jpg";
import heroSlider4 from "./images/hero-slider-img_4.jpg";
import heroSlider5 from "./images/hero-slider-img_5.jpg";
import sliderIcon1 from "./images/rafael-icon1.png";
import sliderIcon2 from "./images/rafael-icon2.png";
import sliderIcon3 from "./images/rafael-icon3.png";
import sliderIcon4 from "./images/rafael-icon4.png";
import sliderIcon5 from "./images/rafael-icon5.png";
import sliderIcon6 from "./images/rafael-icon6.png";
import productAction from "./images/product-img-action.png";
import productLanding from "./images/product-landing-img.png";
import alzaScore from "./images/alza_score_49.png";
import allegroScore from "./images/allegro-score.png";
import mallScore from "./images/mall-score.png";
import logoWebtories from "./images/WEBTORIES-logo-black.png";
